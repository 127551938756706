import * as React from "react"

import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import { buildPageDescription, fixRelativePath } from "../lib/helper"
import FormattedTitle from "../components/common/FormattedTitle"
import { SEO as SearchEngineOptimization } from "../components/common/seo"

const PublishingPage = ({ data }) => {
  const { title, body = {} } = data?.drupal?.entity || {}
  const processedBody = fixRelativePath(body?.processed || "")
  return (
    <Layout>
      <h1>
        <FormattedTitle forceUnderline={true}>{title}</FormattedTitle>
      </h1>
      <div className="content-wrapper container">
        <div className="pb-2 pt-5">
          <iframe
            allow="encrypted-media"
            allowTransparency="true"
            frameBorder="0"
            height="420"
            src="https://open.spotify.com/embed/playlist/46JgAZcw75wqJFvc7lHLTv"
            width="100%"
            title="Spotify Playlist"
          ></iframe>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: processedBody }}
          className="node-body py-4"
        />
      </div>
    </Layout>
  )
}

export default PublishingPage

export const pageQuery = graphql`
  query {
    drupal {
      entity: nodeByPath(path: "/publishing") {
        entityId
        title: entityLabel
        entityUrl {
          path
        }
        ... on Default_NodePage {
          body {
            processed
          }
          image: fieldImage {
            url
            id: targetId
          }
          thumbnail: gatsbyImage {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
  }
`

export const Head = ({ pageContext }) => {
  const { title, body } = pageContext
  const processedBody = fixRelativePath(body?.processed || "")
  const description = buildPageDescription(processedBody)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname="/publishing"
    />
  )
}
